var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          ref: "daily-work-availability-form",
          attrs: {
            id: "daily-work-availability-form",
            submitAction: _vm.isEdit ? _vm.update : _vm.create,
            submitSuccessAction: _vm.isEdit
              ? _vm.updateSuccess
              : _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasMultipleOffices,
                  expression: "hasMultipleOffices"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "officeId",
                  placeholder: _vm.$t("offices.office"),
                  dataSource: _vm.offices,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.data.officeId,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "officeId", $$v)
                  },
                  expression: "data.officeId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-datepicker", {
                attrs: {
                  id: "validFrom",
                  placeholder: _vm.$t("shared.validFrom")
                },
                model: {
                  value: _vm.data.validFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "validFrom", $$v)
                  },
                  expression: "data.validFrom"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-datepicker", {
                attrs: { id: "validTo", placeholder: _vm.$t("shared.validTo") },
                model: {
                  value: _vm.data.validTo,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "validTo", $$v)
                  },
                  expression: "data.validTo"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "isDayOff",
                  placeholder: _vm.$t("isDayOff.title"),
                  dataSource: _vm.isDayOff,
                  fields: { text: "name", value: "id" },
                  cssClass: "e-field"
                },
                model: {
                  value: _vm.data.isDayOff,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "isDayOff", $$v)
                  },
                  expression: "data.isDayOff"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "form-group",
              staticStyle: { "margin-top": "30px" }
            },
            [
              _c("table", [
                _c("colgroup", [
                  _c("col", { attrs: { width: "160px" } }),
                  _c("col", { attrs: { width: "8px" } }),
                  _c("col", { attrs: { width: "160px" } })
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.data.hours, function(item, index) {
                    return _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.data.isDayOff,
                            expression: "!data.isDayOff"
                          }
                        ],
                        key: item.id,
                        attrs: { index: item.id }
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("app-timepicker", {
                              attrs: {
                                id: `startTime_${item.id}`,
                                "data-index": `startTime_${index}`,
                                placeholder: _vm.$t("shared.startTime")
                              },
                              model: {
                                value: item.startTime,
                                callback: function($$v) {
                                  _vm.$set(item, "startTime", $$v)
                                },
                                expression: "item.startTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c("td", { staticStyle: { "padding-top": "28px" } }, [
                          _vm._v("-")
                        ]),
                        _c(
                          "td",
                          [
                            _c("app-timepicker", {
                              attrs: {
                                id: `endTime_${item.id}`,
                                "data-index": `endTime_${index}`,
                                placeholder: _vm.$t("shared.endTime")
                              },
                              model: {
                                value: item.endTime,
                                callback: function($$v) {
                                  _vm.$set(item, "endTime", $$v)
                                },
                                expression: "item.endTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticStyle: { "padding-top": "28px" } },
                          [
                            index > 0
                              ? _c("app-button", {
                                  attrs: {
                                    type: "button",
                                    iconCss: "e-delete e-icons",
                                    title: _vm.$t("employees.deleteWorkTime")
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.removeHours(item.id)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticStyle: { "padding-top": "28px" } },
                          [
                            index == _vm.data.hours.length - 1
                              ? _c("app-button", {
                                  attrs: {
                                    type: "button",
                                    iconCss: "e-add e-icons",
                                    title: _vm.$t("employees.addWorkTimeFor")
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.addHours(item.id)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ]
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: _vm.isEdit ? "shared.edit" : "shared.add",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }